<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'title')" type="text" placeholder="Search by job title">
                    </div>
                </div>
                <div>
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
                            <option :value="''">Select status</option>
                            <option :value="0">Inreview</option>
                            <option :value="1">Live</option>
                            <option :value="2">Rejected</option>
                        </select>
                    </div>
                </div>
                <div class="uk-form-controls uk-width-small">
                  <select class="uk-select" id="form-horizontal-select" v-model="meta.type" @change="(e) => changeMeta(e, 'type')">
                    <option :value="''">Select type</option>
                    <option :value="'partner'">Partner</option>
                    <option :value="'kormo'">Kormo</option>
                  </select>
                </div>
                <div class="uk-inline">
                  <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal">Create</button>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center">Title</th>
                            <th class="uk-text-center uk-width-1-4">Address</th>
                            <th class="uk-text-center">Industry</th>
                            <th class="uk-text-center">Salary</th>
                            <th class="uk-text-center">Status</th>
                            <th class="uk-text-center uk-width-1-4">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="jobs.docs.length>0">
                        <tr v-for="(job, i) in jobs.docs" :key="i">
                            <td>{{job.title}}</td>
                            <td>{{job.address}}</td>
                            <td>{{job.job_industry ? job.job_industry.name : '-'}}</td>
                            <td>{{job.salary}} {{job.salary ? `/${job.salary_unit}` : '-'}}</td>
                            <td><label-status :type="'job'" :status="job.status" /></td>
                            <td>
                                <template v-if="job.status == 0">
                                    <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-primary" @click="jobAction(job._id, 'approve')">Approve</button><br/>
                                    <button style="width:100px; cursor:pointer; height:30px; margin-bottom:5px" class="uk-button-danger" @click="showModalReject(job._id)">Reject</button><br/>
                                    <!-- <button style="width:100px; cursor:pointer; height:30px" class="uk-button-default" @click="goToApplicant(job._id)">Applicants</button> -->
                                    <br>
                                </template>
                                <button
                                  style="width:100px; cursor:pointer; margin-bottom:10px; height:30px; background:#333747"
                                  class="uk-button-primary uk-margin-right"
                                  v-clipboard:copy="job._id"
                                  v-clipboard:success="onCopy">
                                  Copy ID
                                </button>
                                <button
                                  style="width:100px; cursor:pointer; height:30px"
                                  class="uk-button-default"
                                  @click="editJob(job)">
                                  Edit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="jobs.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="formJobModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideJobModal"></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Job Form</h2>
            </div>
            <div class="uk-modal-body">

              <div class="uk-margin">
                <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="jobForm.company_id"
                  placeholder="Type to search company"
                  label="name"
                  name="company"
                  track-by="name"
                  v-validate="'required'"
                  :options="company_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setCompanyValues"
                >
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Job Industry <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="jobForm.job_industry_id"
                  placeholder="Job Industry"
                  label="name"
                  name="job_industry"
                  track-by="name"
                  v-validate="'required'"
                  :options="industry_options">
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('job_industry')">{{ errors.first('job_industry') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Job Title <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('title')}"
                  name="title"
                  type="text"
                  v-model="jobForm.title"
                  v-validate="'required'"
                  placeholder="Job Title">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">{{ errors.first('title') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">SEO URL <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('seo_url')}"
                  name="seo_url"
                  type="text"
                  v-model="jobForm.seo_url"
                  v-validate="'required'"
                  placeholder="SEO URL">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('seo_url')">{{ errors.first('seo_url') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Job Description <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('description')}"
                  name="description"
                  type="text"
                  v-model="jobForm.job_description"
                  v-validate="'required'"
                  placeholder="Description">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Address <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('address')}"
                  name="address"
                  type="text"
                  v-model="jobForm.address"
                  v-validate="'required'"
                  placeholder="Address">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('address')">{{ errors.first('address') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Company Office (Property) <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="jobForm.company_office_id"
                  placeholder="Type to search property"
                  label="name"
                  name="property"
                  track-by="name"
                  v-validate="'required'"
                  :options="property_options"
                  :searchable="true"
                  :loading='isFetching'
                  :internal-search="false"
                  :options-limit="50"
                  @search-change="setPropertyValues"
                >
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('property')">{{ errors.first('property') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Working Hours <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('working_hours')}"
                  name="working_hours"
                  type="number"
                  v-model="jobForm.working_hours"
                  v-validate="'required'"
                  placeholder="Working Hours">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('working_hours')">{{ errors.first('working_hours') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Type</label>
                <multiselect
                  v-model="jobForm.type"
                  placeholder="Select Type"
                  name="type"
                  :options="type_options">
                </multiselect>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Mitra Fee</label>
                <input
                  class="uk-input"
                  name="mitra_fee"
                  type="number"
                  v-model="jobForm.mitra_fee"
                  placeholder="Mitra Fee">
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Mitra Fee Unit</label>
                <multiselect
                  v-model="jobForm.mitra_fee_unit"
                  placeholder="Select Fee Unit"
                  name="mitra_fee_unit"
                  :options="['hari', 'minggu', 'bulan', 'tahun']">
                </multiselect>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Status <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="jobForm.status"
                  placeholder="Select Status"
                  label="name"
                  name="job_status"
                  track-by="name"
                  v-validate="'required'"
                  :options="status_options">
                </multiselect>
                <span class="uk-text-small uk-text-danger" v-show="errors.has('job_status')">{{ errors.first('job_status') }}</span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Required Test <b class="uk-text-danger">*</b></label>
                <multiselect
                  v-model="jobForm.required_test"
                  placeholder="Select Category"
                  label="name"
                  name="category"
                  track-by="_id"
                  :multiple="true"
                  :options="categories">
                </multiselect>
              </div>

              <div class="uk-margin">
                <template v-if="jobForm.required_test.length > 0">
                    <template v-for="category in jobForm.required_test" >
                        <label class="uk-form-label" :key="category.name">{{category.name}} Minimum Score <b class="uk-text-danger">*</b></label>
                        <input
                        class="uk-input"
                        name="minimum_score"
                        type="number"
                        v-model="category.minimum_score"
                        max="100"
                        min="0"
                        :key="category._id">
                    </template>
                </template>

              </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary" type="button" @click="saveJob">Save</button>
            </div>
          </div>
        </div>

        <div id="job-reject" uk-modal esc-close="false" bg-close="false">
          <form class="uk-modal-dialog" data-vv-scope="reject-form">
            <div>
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Job Rejected</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Reason</label>
                        <div class="uk-width-1-1 uk-inline">
                            <input
                                class="uk-input"
                                type="text"
                                placeholder="Reason rejected"
                                v-model="reason"
                                name="reason"
                                v-validate="'required'"
                                :class="{'uk-form-danger': errors.has('reject-form.reason')}">
                        </div>
                        <span v-show="errors.has('reject-form.reason')" class="uk-text-small uk-text-danger">{{ errors.first('reject-form.reason') }}</span>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" @click="hideModal">Cancel</button>
                    <button class="uk-button uk-button-primary uk-margin-small-left" type="button" @click="submitRejectJob">Reject</button>
                </div>
            </div>
          </form>
        </div>
    </div>
</template>

<script>
import api from '@/utils/api';
import { API_JOB } from '@/utils/api-url';
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LabelStatus from '@/components/globals/LabelStatus';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                status: '',
                limit: 100,
                page: 1,
                type: ''
            },
            select_job: null,
            reason: null,
            jobForm: {
                type: null,
                company_id: '',
                job_industry_id: '',
                company_office_id: '',
                seo_url: '',
                title: '',
                address: '',
                geoloc: null,
                working_hours: null,
                job_description: '',
                status: '',
                mitra_fee: null,
                mitra_fee_unit: null,
                mitra_myrobin_comission: null,
                required_test: [],
            },
            isLoading: false,
            isFetching: false,
            formIsEdit: false,
            status_options: [
                { name: 'Rejected', value: 2},
                { name: 'Live', value: 1},
                { name: 'Inreview', value: 0}
            ],
            type_options: ['partner'],
            company_options: [],
            industry_options: [],
            property_options: [],
            categories: [],
        };
    },
    components: {
        Pagination,
        EmptyTable,
        LabelStatus
    },
    computed : {
        ...mapGetters({
            jobs: 'job/jobs',
            job: 'job/job',
            companies: 'company/companies',
            company_industries: 'company/company_industries',
            properties: 'property/properties',
        })
    },
    watch: {
        meta() {
            this.setJobMeta();
        },
    },
    mounted() {
        this.setJobMeta();
        this.setIndustryValues();
        this.getCategories();
    },
    methods: {
        ...mapActions({
            getJob: 'job/getJob',
            createJob: 'job/createJob',
            updateJob: 'job/updateJob',
            getCompanies: 'company/getCompanies',
            getCompanyIndustries: 'company/getCompanyIndustries',
            getProperties: 'property/getProperties',
            getTestCategoryLists: 'test_category/getTestCategoryLists',
        }),
        async getCategories() {
            let response = await this.getTestCategoryLists();
            this.categories = response.docs;
        },
        fullName(data) {
            return formatter.getFullname(data);
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setIndustryValues() {
            await this.getCompanyIndustries();
            this.industry_options = this.company_industries.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );
        },
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.property_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        setJobMeta() {
            this.getJob(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async showModalReject(job_id) {
            this.select_job = job_id;
            await window.UIkit.modal('#job-reject').show();
        },
        async hideModal() {
            await window.UIkit.modal('#job-reject').hide();
            this.select_job = null;
            this.reason = null;
            this.$validator.reset();
        },
        submitRejectJob() {
            this.$validator.validateAll('reject-form').then((success) => {
                if(success) {
                    if (this.$validator.errors.any()) return;
                    this.jobAction(null, 'reject');
                }
            });
        },
        async jobAction(data, action) {
            try {
                await api.apiPatchAuth(API_JOB.ACTION(data || this.select_job, action), {reject_reason: this.reason});
                notificationSuccess('Success update job');
                this.hideModal();
                this.getJob(this.meta);
            } catch (err) {
                notificationDanger(err);
            }
        },
        goToApplicant(job_id) {
            this.$router.push(`/admin/applicant/${job_id}`);
        },
        editJob(job) {
            this.formIsEdit = true;
            this.fillForm(job);
            this.showModal();
        },
        async saveJob() {
            try {
                const validate = await this.$validator.validateAll();
                if (process.env.NODE_ENV !== 'production') {
                    console.log(this.$validator.errors);
                }
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                [
                    'company_id', 'company_office_id',
                    'job_industry_id', 'status'
                ].forEach(prop => {
                    this.jobForm[prop] = this.jobForm[prop].value;
                });

                if (this.jobForm.required_test.length > 0) {
                    let tmp = this.jobForm.required_test;
                    this.jobForm.required_test = [];
                    tmp.forEach(element => {
                        this.jobForm.required_test.push(
                            {
                                test_category_id: element._id,
                                minimum_score: element.minimum_score
                            }
                        );
                    });
                }

                const response = this.formIsEdit
                    ? await this.updateJob(this.jobForm)
                    : await this.createJob(this.jobForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Job saved!');
                    this.hideJobModal();
                    this.setJobMeta();
                } else {
                    notificationDanger('Failed to save job.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showModal() {
            await window.UIkit.modal('#formJobModal').show();
        },
        async hideJobModal() {
            await window.UIkit.modal('#formJobModal').hide();
            this.resetForm();
        },
        fillForm(job) {
            try {
                this.jobForm.id = job._id;
                this.jobForm.type = job.type === "myrobin" ? null : job.type;
                [
                    'seo_url', 'title', 'address', 'geoloc',
                    'working_hours', 'job_description', 'mitra_fee',
                    'mitra_fee_unit', 'mitra_myrobin_comission'
                ].forEach(prop => {
                    this.jobForm[prop] = job[prop];
                });
                this.jobForm.status = this.status_options.find(
                    e => e.value === job.status
                );
                this.jobForm.company_id = {
                    name: job.company.name,
                    value: job.company._id
                };
                this.jobForm.job_industry_id = {
                    name: job.job_industry.name,
                    value: job.job_industry._id
                };
                this.jobForm.company_office_id = {
                    name: job.company_office.name,
                    value: job.company_office._id
                };
                if (job.required_test && job.required_test.length > 0) {
                    job.required_test.forEach(element => {
                        this.jobForm.required_test.push(
                            {
                                _id: element.test_category_id._id,
                                name: element.test_category_id.name,
                                minimum_score: element.minimum_score
                            }
                        );
                    });
                }

            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.jobForm = {
                type: null,
                company_id: '',
                job_industry_id: '',
                company_office_id: '',
                seo_url: '',
                title: '',
                address: '',
                geoloc: null,
                working_hours: null,
                job_description: '',
                status: '',
                mitra_fee: null,
                mitra_fee_unit: '',
                mitra_myrobin_comission: '',
                required_test: []
            };
            this.$validator.reset();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
    },
};
</script>
